@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&family=Sora:wght@300;400;500;600;700&display=swap');
@font-face {
  font-family: "SF Pro Display";
  src: url("./assets/font/SFProDisplay-Medium.eot");
  src: url("./assets/font/SFProDisplay-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/font/SFProDisplay-Medium.woff2") format("woff2"),
    url("./assets/font/SFProDisplay-Medium.woff") format("woff"),
    url("./assets/font/SFProDisplay-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("./assets/font/SFProDisplay-Bold.eot");
  src: url("./assets/font/SFProDisplay-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/font/SFProDisplay-Bold.woff2") format("woff2"),
    url("./assets/font/SFProDisplay-Bold.woff") format("woff"),
    url("./assets/font/SFProDisplay-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("./assets/font/SFProDisplay-Regular.eot");
  src: url("./assets/font/SFProDisplay-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/font/SFProDisplay-Regular.woff2") format("woff2"),
    url("./assets/font/SFProDisplay-Regular.woff") format("woff"),
    url("./assets/font/SFProDisplay-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("./assets/font/SFProDisplay-Medium_1.eot");
  src: url("./assets/font/SFProDisplay-Medium_1.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/font/SFProDisplay-Medium_1.woff2") format("woff2"),
    url("./assets/font/SFProDisplay-Medium_1.woff") format("woff"),
    /* url('./assets/font/SFProDisplay-Medium_1.ttf') format('truetype'); */
      url("./assets/font/SFProDisplay-Medium_1.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  padding: 0;
  font-family: "SF Pro Display";
  font-weight: 400;
  background-color: #0b0c1e;
  color: #fff;
  overflow-x: hidden;
}

a {
  color: #00ffcc;
  text-decoration: none;
}

a:hover {
  color: #ff437f;
  text-decoration: underline;
}

/* Custom Scrollbar */
*::-webkit-scrollbar {
  width: 6px;
  height: 5px;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
}

*::-webkit-scrollbar-thumb {
  background-color: #24bcfd;
}

img.brand_logo {
  width: 162px;
}

.main_navbar {
  z-index: 200;
  border-bottom: 1px solid #02243d;
  background: #0b0c1e;
  backdrop-filter: blur(75px);
}
.main_navbar .menu {
  display: flex;
  align-items: center;
  font-family: Sora;
  gap: 30px;
  color: #9d9e9e;
}
.main_navbar .menu a {
  color: #9d9e9e;
  text-decoration: none !important;
}
.main_navbar .menu a:hover,
.main_navbar .menu a.active {
  color: #e4e4e7;
}
.main_navbar .dropdown-menu {
  border-radius: 10px;
  border: 1px solid rgba(68, 68, 79, 0.25);
  background: rgba(2, 2, 8, 0.5);
  backdrop-filter: blur(25px);
  padding: 15px;
}
.main_navbar .dropdown-menu a {
  padding: 5px;
  display: block;
  width: 100%;
  color: #e4e4e7;

  /* font-family: Sora;  */
  font-size: 16px;
}
.main_navbar .dropdown-menu a:hover ,
.main_navbar .dropdown-menu a:focus{
  border-radius: 5px 5px;
  background: linear-gradient( 90deg, rgb(0 160 227 / 2%) -0.89%, rgb(2 36 61 / 38%) 100% ) !important;
}
.main_navbar .dropdown-toggle {
  border: 0 !important;
  padding-right: 30px;
}
.main_navbar .dropdown-toggle::after {
  background: url("./assets/images/down_arw.png") no-repeat center;
  border: 0;
  width: 10px;
  height: 7px;
  filter: invert(0) brightness(2.5);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  right: 8px;
}
.main_navbar .navbar_right {
  display: flex;
  margin-left: auto;
  width: fit-content;
}

.navbar-sticky--moved-up {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin-top: -100px;
  -webkit-box-shadow: 0 0.1875rem 0.9375rem 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 0.1875rem 0.9375rem 0 rgba(0, 0, 0, 0.15);
}

.navbar-sticky--on {
  margin-top: 0;
}

.navbar-sticky--transitioned {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.offcanvas {
  background: #0b0c1e;
}
.btn-close {
  filter: invert(1);
}
.navbar-toggler {
  box-shadow: none !important;
}
.navbar-brand {
  outline: 0 !important;
  border: 0;
}

/* Connect Wallet */
.primary_modal .modal-content {
  border: 1px solid #02243d;
  background: rgb(5 6 15 / 74%);
  backdrop-filter: blur(5.5px);
}

.primary_modal .modal-header {
  border-bottom: 1px solid #080d1c;
  padding: 20px 24px 15px;
}

.connect_wallet_button {
  border: 2px solid #080d1c;
  background-color: transparent;
  color: #ffffff;
  border-radius: 10px;
  padding: 50px 14px;
  outline: 0;
  line-height: 46px;
  height: 52px;
  font-size: 18px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  transition: 0.5s ease-out;
}

.primary_modal .btn-close {
  filter: invert(1);
}

.connect_wallet_button:hover {
  border-color: rgba(36, 188, 253, 0.5);

  background: radial-gradient(
    159.44% 159.44% at 50% 13.41%,
    #0b0c1e 0%,
    #0b0c1e 35.8%,
    #003155 100%
  );
  box-shadow: 1px 1px 2px 0px rgba(36, 188, 253, 0.25) inset,
    0px 0px 75px 0px rgba(36, 188, 253, 0.25);
}

.primary_modal .modal-header h5 {
  font-size: 24px;
  font-weight: 500;
  margin: 0;
  color: #fff;
}

.connect_wallet_button span {
  color: #fff;
  font-size: 22px;
  font-weight: 400;
  white-space: nowrap;
}

.btn-close {
  width: 32px;
  height: 32px;
  color: #fff;
  border: 0;
  /* background: transparent url("../src/assets/images/close_icon.png") no-repeat; */
  opacity: 0.8;
  outline: none;
  box-shadow: none !important;
}

.btn-close:hover {
  opacity: 1;
}

.primary_modal a {
  text-decoration: none;
}

.primary_btn {
  text-decoration: none !important;
  display: inline-flex;
  align-items: center;
  gap: 0.75rem;
  border-radius: 50px;
  color: #fff !important;
  font-weight: 500;
  padding: 15px 20px;
  background-color: #02243d;
  transition: background-color 0.3s;
  border: 0 !important;
  box-shadow: none !important;
}

.button__icon-wrapper {
  flex-shrink: 0;
  width: 25px;
  height: 25px;
  position: relative;
  color: #02243d;
  background-color: #24bcfd;
  border-radius: 50%;
  display: grid;
  place-items: center;
  overflow: hidden;
}

.primary_btn:hover .button__icon-wrapper {
  color: #000;
}

.button__icon-svg--copy {
  position: absolute;
  transform: translate(-150%, 150%);
}

.primary_btn:hover .button__icon-svg:first-child {
  transition: transform 0.3s ease-in-out;
  transform: translate(150%, -150%);
}

.primary_btn:hover .button__icon-svg--copy {
  transition: transform 0.3s ease-in-out 0.1s;
  transform: translate(0);
}

.h2tag {
  background: linear-gradient(91deg, #24bcfd -5.76%, #fff 106.09%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.h3tag {
  background: linear-gradient(91deg, #24bcfd -5.76%, #fff 106.09%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
}
.h4tag {
  background: linear-gradient(91deg, #24bcfd -5.76%, #fff 106.09%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 28px;
  font-weight: 500;
  display: inline-block;
}
.h5tag {
  color: #fff;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.page_header {
  background: url("../src/assets/images/banbg.png") no-repeat top;
  background-size: 100%;
  height: 880px;
}

.page_header .box {
  border-radius: 5px;
  border: 1px solid #02243d;
  background: rgba(11, 12, 30, 0.01);
  backdrop-filter: blur(37.5px);
  display: inline-flex;
  padding: 35px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
}

.platform {
  background: url("./assets/images/platform_bg.png") no-repeat center;
  padding-bottom: 60px;
}
.platform .platform_bg {
  border-right: 1px solid #02243d;
  border-bottom: 1px solid #02243d;
  border-left: 1px solid #02243d;
  background: rgba(11, 12, 30, 0.01);
  backdrop-filter: blur(100px);
  padding: 40px;
}
.platform .flx {
  display: flex;
  align-items: center;
  gap: 24px;
}
.platform .box {
  padding: 15px 30px;
  border-radius: 5px;
  border: 1px solid #191a30;
  background: linear-gradient(262deg, #0b0f22 3.02%, #0b0c1e 97.55%);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;
  width: 100%;
  color: #64657b;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  transition: 0.5s ease-out;
}
.platform .box:hover {
  color: #e7e7e7;
  border-radius: 5px;
  border: 1px solid rgba(36, 188, 253, 0.5);
  background: radial-gradient(
    159.44% 159.44% at 50% 13.41%,
    #0b0c1e 0%,
    #0b0c1e 35.8%,
    #003155 100%
  );
  box-shadow: 0px 3px 2px 0px rgba(36, 188, 253, 0.25) inset,
    0px 0px 75px 0px rgba(36, 188, 253, 0.25);
}
.platform .box:hover img {
  filter: invert(1) brightness(10);
}

.features {
  padding: 40px 0;
}
.features_bg {
  position: relative;
  z-index: 1;
}
.features .features_bg::before {
  content: "";
  position: absolute;
  background: url("./assets/images/feature_bg.png") no-repeat center;
  width: 100%;
  height: 100%;
  background-position: center;
  z-index: -1;
  left: -300px;
}
.features h5 {
  font-size: 22px;
  margin-bottom: 15px;
}
.features p {
  color: #999aa8;
  line-height: 26px;
  margin-bottom: 0;
}
.features .box {
  border-radius: 10px;
  border: 1px solid rgba(25, 26, 48, 0.5);
  background: linear-gradient(262deg, #0b0f22 3.02%, #0b0c1e 97.55%);
  padding: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: 0.5s ease-out;
}

.features .box:hover {
  border: 1px solid rgba(36, 188, 253, 0.25);
  box-shadow: 0px 6px 15px 0px rgba(36, 188, 253, 0.1) inset;
  background: radial-gradient(
    159.44% 159.44% at 50% 13.41%,
    rgba(11, 12, 30, 0.05) 0%,
    rgba(11, 12, 30, 0.05) 35.8%,
    rgba(0, 49, 85, 0.05) 100%
  );
  backdrop-filter: blur(12.5px);
}
.features .box p {
  margin-right: 10px;
}
.features .img_box {
  border: 1px solid #191a30;
  background: linear-gradient(262deg, #0b0f22 3.02%, #0b0c1e 97.55%);
  min-width: 133px;
  height: 133px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 32px;
  position: relative;
  z-index: 1;
}
.features .img_box::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
  display: block;
  background: #0c162b;
  filter: blur(21px);
  z-index: -1;
}

.how {
  padding: 40px 0;
}
.how p {
  color: #999aa8;
}
.how .accordion-button {
  font-size: 22px;
}
.how .accordion-body p span {
  color: #24bcfd;
  font-weight: 700;
}

.how .accordion {
  position: relative;
}

.how .accordion::before {
  content: "";
  position: absolute;
  background: #191a30;
  width: 1px;
  left: 0;
  top: -10px;
  bottom: 0;
  height: 74%;
  margin: auto 0;
}

.how .accordion-item {
  box-shadow: none !important;
  border: 0;
  background: transparent;
  border-radius: 0;
  padding: 2px 0;
  padding-left: 30px;
  position: relative;
  padding-bottom: 10px;
}

.how .accordion-item::before {
  content: "";
  position: absolute;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  /* background: linear-gradient(283deg, #FFF 8.22%, #9CFF45 48.93%, rgba(0, 180, 255, 0.78) 101.26%);
  border: 1px solid rgba(228, 228, 231, 0.50); */
  background: url("./assets/images/li_icon.png");
  left: -12px;
  top: 12px;
}

.how .accordion-item button {
  color: #e4e4e7;
  font-weight: 500;
  font-size: 20px;
  background: transparent;
  box-shadow: none !important;
  padding: 10px 0;
}

.how .accordion-body {
  padding: 0;
  font-size: 18px;
  font-weight: 400;
  color: #e4e4e7;
}

.how .accordion-button:not(.collapsed)::after,
.how .accordion-button.collapsed::after {
  display: none;
}

.faq {
  padding: 50px 0;
  background: url("./assets/images/faq.png") no-repeat center bottom;
}

.faq .accordion-item {
  box-shadow: none !important;
  border: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  background: transparent;
  border-radius: 0;
  padding: 20px 30px;
  position: relative;
}

.faq .accordion-item:nth-last-child(1) {
  border: 0;
}

.faq .accordion-item button {
  color: #fff;
  font-weight: 400;
  font-size: 20px;
  background: transparent;
  box-shadow: none !important;
  padding: 10px 0;
}
.faq .accordion-item [aria-expanded="true"] {
  color: #24bcfd;
}

.faq .para {
  color: #fff;
  margin-bottom: 0;
}

.faq .accordion-body {
  padding: 15px 0;
}

.faq .accordion-button:not(.collapsed)::after,
.faq .accordion-button.collapsed::after {
  background: url("./assets/images/arw.png") no-repeat center;
  width: 13px;
  height: 8px;
  transition: 0.5s;
}

footer.footer {
  background: #0f1021;
  padding-top: 30px;
}
footer.footer .para {
  color: #999aa8;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 162.5% */
}

.footer .right_box {
  width: fit-content;
  margin-left: auto;
}
.footer_panel {
  border-bottom: 1px solid rgba(68, 68, 79, 0.75);
  margin-bottom: 10px;
}
.footer_social_links {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 0;
  list-style-type: none;
}
.footer_social_links a {
  border-radius: 32px;
  background: rgba(52, 52, 64, 0.69);
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff !important;
  font-size: 18px;
  text-decoration: none !important;
  transition: 0.5s ease-out;
}
.footer_social_links a:hover {
  border: 1px solid rgba(36, 188, 253, 0.5);
  background: radial-gradient(
    159.44% 159.44% at 50% 13.41%,
    #0b0c1e 0%,
    #0b0c1e 35.8%,
    #003155 100%
  );
  box-shadow: 0px 3px 2px 0px rgba(36, 188, 253, 0.25) inset,
    0px 0px 25px 0px rgba(36, 188, 253, 0.35);
}
.footer .blw {
  color: #e4e4e7;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: normal;
  padding-bottom: 40px;
}
.footer .blw a {
  color: #e4e4e7;
  text-decoration: none !important;
}
.footer .blw ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  align-items: center;
  gap: 20px;
}
.footer .blw li {
  position: relative;
}
.footer .blw li:not(:last-child):before {
  content: "";
  position: absolute;
  background: #e4e4e7;
  width: 1px;
  height: 90%;
  top: 0;
  bottom: 0;
  margin: auto 0;
  right: -12px;
}

.page_box {
  min-height: 100vh;
  padding-top: 70px;
}
.head_box {
  border-radius: 50px;
  background: rgba(52, 52, 64, 0.1);
  backdrop-filter: blur(25px);
  padding: 20px;
  text-align: center;
}
.head_box h3 {
  color: #e4e4e7;
  text-align: center;
  font-family: Sora;
  font-size: 40px;
  font-weight: 400;
  letter-spacing: -2px;
  margin-bottom: 0;
}
.staking .plan_box {
  border-radius: 30px 30px 0px 0px;
  background: linear-gradient(
    90deg,
    rgba(0, 160, 227, 0.13) 0%,
    rgba(52, 52, 64, 0) 51.56%,
    rgba(156, 255, 69, 0.13) 100%
  );
  backdrop-filter: blur(25px);
  padding: 15px 20px;
  max-width: 80%;
  margin: 0 auto;
  text-align: center;
}
.staking .box {
  border-radius: 30px;
  border: 1px solid rgba(0, 160, 227, 0.13);
  background: linear-gradient(
    139deg,
    rgba(0, 160, 227, 0.08) -4.79%,
    rgba(52, 52, 64, 0) 104.38%
  );
  backdrop-filter: blur(7.5px);
  padding: 40px;
}
.staking .box .inbox {
  border-radius: 15px;
  border: 1px solid rgba(68, 68, 79, 0.25);
  background: rgba(52, 52, 64, 0.1);
  backdrop-filter: blur(75px);
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.staking .box p {
  color: #9d9e9e;
  margin-bottom: 0;
}
.staking .box span {
  color: #e4e4e7;
}
.staking .box .font p,
.staking .box .font span {
  font-size: 18px;
}
.staking .box .accordion-button {
  color: #9d9e9e;
  box-shadow: none !important;
  background: transparent !important;
  border: 0 !important;
  gap: 5px;
  justify-content: center;
}
.staking .box .accordion-button::after {
  margin-left: 0;
  background-image: url("./assets/images/down_arw.png");
  width: 10px;
  height: 7px;
  background-size: contain;
}

.staking .box .accordion-button[aria-expanded="false"] .hide {
  display: none;
}
.staking .box .accordion-button[aria-expanded="true"] .hide {
  display: block;
}

.staking .box .accordion-button[aria-expanded="true"] .detail {
  display: none;
}
.staking .box .accordion-button[aria-expanded="false"] .detail {
  display: block;
}
.staking .box .progress {
  background: #9494a980;
  border-radius: 40px;
  height: 10px;
}
.staking .box .progress-bar {
  border-radius: 40px;
  background-color: #9cff45;
  background-image: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.15) 25%,
    #72cd22 25%,
    #72cd22 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    #72cd22 75%,
    #72cd22
  );
}
.staking .box .blue .progress-bar {
  background-color: #00b4ff;
  background-image: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.15) 25%,
    #0079d0 25%,
    #0079d0 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    #0079d0 75%,
    #0079d0
  );
}

.page_box {
  position: relative;
  z-index: 1;
}

.detail_box {
  border-radius: 30px;
  border: 1px solid rgba(68, 68, 79, 0.25);
  background: rgba(52, 52, 64, 0.1);
  backdrop-filter: blur(25px);
  padding: 40px;
  display: flex;
  gap: 24px;
  align-items: center;
}
.detail_box .box {
  border-radius: 15px;
  border: 1px solid rgba(68, 68, 79, 0.25);
  background: rgba(52, 52, 64, 0.1);
  backdrop-filter: blur(75px);
  padding: 20px;
  display: flex;
  align-items: center;
  gap: 20px;
  width: 28%;
}
.detail_box .box:not(:first-child) {
  width: 36%;
}
.detail_box .box p span {
  color: #9d9e9e;
}

.staking .box .claim {
  background: linear-gradient(#02040b, #02040b) padding-box,
    linear-gradient(to right, #00b4ffcc, #9cff45ed) border-box;
}
.page_box .nav {
  background: rgba(52, 52, 64, 0.05);
  backdrop-filter: blur(75px);
  border: 0;
  margin-bottom: 15px;
  border-radius: 10px;
}
.page_box .nav li.nav-item {
  width: 33.33333%;
  text-align: center;
}
.page_box .nav .nav-link {
  width: 100%;
  border-radius: 0;
  color: #414148;
  text-align: center;
  font-family: Sora;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
}
.page_box .nav .nav-link.active,
.page_box .nav .nav-link:hover {
  border: 1px solid rgba(0, 160, 227, 0.15);
  background: linear-gradient(
    165deg,
    rgba(0, 160, 227, 0.1) -6.16%,
    rgba(52, 52, 64, 0) 111.08%
  );
  backdrop-filter: blur(7.5px);
  color: #e4e4e7;
}
.page_box .nav li.nav-item:nth-child(1) .nav-link.active,
.page_box .nav li.nav-item:nth-child(1) .nav-link:hover {
  border-radius: 10px 0px 0px 10px;
}
.page_box .nav li.nav-item:nth-child(3) .nav-link.active,
.page_box .nav li.nav-item:nth-child(3) .nav-link:hover {
  border-radius: 0px 10px 10px 0px;
}

/* .claim {
  border-radius: 100px;
  border: 1px solid transparent;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  color: #e4e4e7;
  padding: 5px 15px;
  border: 1px solid rgb(0 180 255 / 0%);
  background: linear-gradient(#101924, #101924) padding-box,
    linear-gradient(to right, #00b4ffcc, #9cff45ed) border-box;
  transition: 0.5s ease-out;
}
.claim:hover,
.claim.hover {
  background: url("./assets/images/btnbg.png") no-repeat center !important;
  background-size: 120% 120% !important;
} */

.history_table ::-webkit-scrollbar {
  width: 6px;
  height: 8px;
}

.history_table ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.116);
  border-radius: 30px;
}

.history_table ::-webkit-scrollbar-thumb {
  background-color: #24bcfd;
  border-radius: 30px;
}

.history_table .flx {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.history_table .flx h4 {
  color: #e4e4e7;
  margin-bottom: 0;
  font-family: Sora;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
}
.history_table .flx p {
  color: #9d9e9e;
  margin-bottom: 0;
}

.history_table .rdt_Table {
  border: 1px solid rgba(255, 255, 255, 0.04);
  background: #0a0e20;
  backdrop-filter: blur(75px);
  border-radius: 20px;
  margin-bottom: 10px;
}
.history_table .rdt_TableRow,
.history_table .rdt_TableHeadRow {
  background: unset;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  border-bottom: 1px solid rgba(255, 255, 255, 0.04) !important ;
  border-right: 1px solid rgba(0, 160, 227, 0);
  border-left: 1px solid rgba(0, 160, 227, 0);
}
.history_table .rdt_TableRow:nth-last-child(1) {
  border: 0 !important;
}

.history_table .rdt_TableHeadRow {
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 4px;
  border-radius: 5px;
  border: 1px solid rgba(36, 188, 253, 0.25) !important;
  background: radial-gradient(
    159.44% 159.44% at 50% 13.41%,
    #0b0c1e 0%,
    #0b0c1e 35.8%,
    #003155 100%
  );
}
.history_table .rdt_TableCol:nth-child(2) > div,
.history_table .rdt_TableCell:nth-child(2) {
  /* justify-content: flex-end; */
}

.history_table .rdt_Pagination {
  background: transparent;
  color: #e4e4e7;
}
.history_table .rdt_Pagination [aria-disabled="true"] {
  fill: #e4e4e7;
}
.history_table .rdt_Pagination [aria-disabled="false"] {
  fill: #9d9e9e;
}

.dashboard .head_flx {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dashboard .head_flx .box {
  display: flex;
  align-items: center;
  gap: 15px;
  border-radius: 10px;
  border: 1px solid rgba(25, 26, 48, 0.5);
  padding: 15px 20px;
  background: linear-gradient(262deg, #0b0f22 3.02%, #0b0c1e 97.55%);
  background: linear-gradient(179deg, #16192a, #4f515a);
}
.dashboard .head_flx p {
  color: #fff !important;
}
.dashboard .head_flx p span {
  color: #999aa8;
}
.dashboard .dashbox {
  border-radius: 10px;
  border: 1px solid rgba(2, 36, 61, 0.5);
  background: rgba(2, 36, 61, 0.05);
  background: linear-gradient(179deg, #16192a, #4f515a);
  backdrop-filter: blur(100px);
  padding: 20px;
}
.dashboard .box {
  border-radius: 5px;
  border: 1px solid rgba(2, 36, 61, 0.5);
  background: #0b0f22;
  padding: 20px;
  display: flex;
  align-items: center;
  gap: 20px;
}
.dashboard .box h5 {
  color: #fff;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.dashboard .box p {
  word-break: break-word;
  color: #999aa8;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 400;
}
.dashboard .box span {
  color: #999aa8;
  font-weight: 400;
  font-size: 14px;
}
.dashboard .box .img_box {
  border-radius: 2.5px;
  background: rgba(2, 36, 61, 0.15);
  min-width: 62px;
  height: 62px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dashboard .box.active {
  border-radius: 5px;
  border: 1px solid rgba(36, 188, 253, 0.25);
  background: radial-gradient(
    159.44% 159.44% at 50% 13.41%,
    #0b0c1e 0%,
    #0b0c1e 35.8%,
    #003155 100%
  );
  box-shadow: 0px 3px 2px 0px rgba(36, 188, 253, 0.25) inset;
  opacity: 1;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.dashboard .box.active::before {
  content: "";
  background: url("./assets/images/active.png") no-repeat center;
  top: -21px;
  width: 104px;
  height: 104px;
  position: absolute;
  right: -21px;
}
.dashboard .box.current {
  border-radius: 5px;
  border: 1px solid rgba(36, 188, 253, 0.25);
  background: radial-gradient(
    159.44% 159.44% at 50% 13.41%,
    #0b0c1e 0%,
    #0b0c1e 35.8%,
    #003155 100%
  );
  opacity: 1;
  box-shadow: 0px 3px 2px 0px rgba(36, 188, 253, 0.25) inset,
    0px 0px 50px 0px rgba(36, 188, 253, 0.25);
}
.tabbox > div {
  border-radius: 10px;
  border: 1px solid rgba(36, 188, 253, 0.25);
  background: radial-gradient(
    159.44% 159.44% at 50% 13.41%,
    #0b0c1e 0%,
    #0b0c1e 35.8%,
    #003155 100%
  );
  color: #1d5f86;
  font-size: 20px;
  font-weight: 400;
  padding: 12px 20px;
  cursor: pointer;
}
.plan .box {
  padding-right: 10px;
  opacity: 0.6;
  width: 100%;
}
.tabbox {
  display: flex;
  align-items: center;
  gap: 20px;
}
.tabbox > div.active {
  color: #fff;
}

.show {
  display: block;
}
.hide {
  display: none;
}
.affiliate span {
  color: #999aa8;
  font-size: 15px;
}
.link {
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.05);
  display: flex;
  padding: 7px;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  gap: 5px;
}
.link p {
  color: #fff !important;
  width: 85%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.social {
  list-style-type: none;
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
}
.social a {
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #02243d;
  color: #0b0f22 !important;
  text-decoration: none !important;
}
.social a:hover {
  background: linear-gradient(91deg, #24bcfd -5.76%, #fff 106.09%);
}
.cursor {
  cursor: pointer;
}
.claim_btn {
  border-radius: 5px;
  border: 1px solid rgba(36, 188, 253, 0.25);
  background: radial-gradient(
    159.44% 159.44% at 50% 13.41%,
    #0b0c1e 0%,
    #0b0c1e 35.8%,
    #003155 100%
  );
  box-shadow: 0px 3px 2px 0px rgba(36, 188, 253, 0.25) inset,
    0px 0px 50px 0px rgba(36, 188, 253, 0.25);
  box-shadow: 0px 3px 2px 0px rgba(36, 188, 253, 0.25) inset,
    inset 0px 0px 0px 0px rgba(36, 188, 253, 0.25);
  padding: 5px 10px;
  color: #fff;
  font-size: 14px;
}
.refferal .id {
  background: url("./assets/images/idbg.png") no-repeat center;
  width: 154px;
  height: 63px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.refferal .id h6 {
  background: linear-gradient(91deg, #24bcfd -5.76%, #fff 106.09%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 0;
  margin-top: 12px;
}
.refferal .box {
  border-radius: 5px;
  border: 1px solid rgba(36, 188, 253, 0.25);
  background: radial-gradient(
    159.44% 159.44% at 50% 13.41%,
    #0b0c1e 0%,
    #0b0c1e 35.8%,
    #003155 100%
  );
  box-shadow: 0px 3px 2px 0px rgba(36, 188, 253, 0.25) inset;
  width: 118px;
  gap: 5px;
  flex-direction: column;
}
.refferal .box span {
  font-size: 15px;
}
.refferal .box p {
  color: #fff;
}
.refferal .inbox {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: fit-content;
}
.refferal .inbox .userimg {
  margin-bottom: -20px;
  z-index: 1;
}
.refferal .inbox .box {
  margin-left: -10px;
  margin-top: -10px;
  z-index: -1;
}
.refferal .ref_flx {
  display: flex;
  margin-bottom: 80px;
}

.scroll > div > div:nth-child(2) {
  background: rgba(2, 36, 61, 0.5) !important;
  border-radius: 10px;
  margin: 4px 0 !important;
}
.scroll > div > div:nth-child(2) > div {
  background: #24bcfd !important;
  border-radius: 10px;
  height: 10px !important;
}

.joinnow {
  padding-top: 50px;
  background-position: top;
  min-height: 100vh;
}
.joinnow .platform_bg {
  border: 1px solid #02243d;
}
.joinnow .box {
  border: 1px solid rgba(36, 188, 253, 0.5);
  background: radial-gradient(
    159.44% 159.44% at 50% 13.41%,
    #0b0c1e 0%,
    #0b0c1e 35.8%,
    #003155 100%
  );
  box-shadow: none !important;
  width: 100% !important;
}
.joinnow input {
  box-shadow: none !important;
  max-width: 80%;
  margin: 0 auto;
  padding: 10px;
  background: #0c1122 !important;
  border-color: #122448 !important;
  color: #fff !important;
}

.box_hover:hover{
  transition: .5s ease-out;
  border: 1px solid rgba(36, 188, 253, 0.25);
  background: radial-gradient(159.44% 159.44% at 50% 13.41%, #0B0C1E 0%, #0B0C1E 35.8%, #003155 100%);
  box-shadow: 0px 3px 2px 0px rgba(36, 188, 253, 0.25) inset, 0px 0px 50px 0px rgba(36, 188, 253, 0.25);
}
.spinner>div{
  margin: 0 auto;
}

@media (max-width: 1799px) {
}
@media (max-width: 1699px) {
  .page_header {
    background-size: unset;
  }
}
@media (max-width: 1399px) {
  .features::before {
    background-position: -40px 100%;
  }
}
@media (max-width: 1199px) {
  .h2tag {
    font-size: 38px;
  }
  .dashboard .head_flx {
    flex-direction: column;
  }
  .dashboard .head_flx .d-flex {
    flex-direction: column;
  }
  .page_header {
    height: unset;
  }
  .page_header h1 span {
    font-size: 66px;
  }
  .staking .box {
    padding: 20px;
    border-radius: 20px;
  }
  .staking .box .inbox {
    padding: 15px;
  }
  .detail_box {
    padding: 20px;
    flex-direction: column;
  }
  .detail_box .box {
    padding: 15px;
    width: 100% !important;
  }
  .page_box .nav .nav-link {
    font-size: 21px;
  }
}
@media (max-width: 991px) {
  .dashboard .box,
  .dashboard .head_flx .box,
  .dashboard .dashbox{
    border: 1px solid rgb(46 116 147);
  }
  .box_hover:hover{
  border: 1px solid rgb(119 213 255) !important;
  }
  .main_navbar {
    backdrop-filter: unset;
    background: #0f1028;
  }
  .main_navbar .menu {
    flex-direction: column;
  }
  .main_navbar .navbar_right {
    flex-direction: column;
    align-items: center;
    gap: 30px;
    width: 100%;
  }
  .page_header {
    background: url("./assets/images/banbg_mob.png") no-repeat center;
    padding: 230px 0;
  }
  .page_header img {
    width: 170px;
  }
  .platform {
    background-position: center top;
  }
  .platform .flx {
    flex-wrap: wrap;
    justify-content: center;
  }
  .platform .box {
    width: 188px;
    flex: unset;
  }
  .detail_box .box {
    flex-direction: column;
    align-items: flex-start;
  }
  .staking .box .inbox > p:nth-child(2) {
    margin-top: 10px;
  }
  .footer .right_box {
    margin-left: 0;
  }
  .footer .blw {
    flex-direction: column;
    align-items: flex-start;
  }
  .features .features_bg::before {
    display: none;
  }
  .features .box {
    flex-direction: column-reverse !important;
    align-items: center !important;
    text-align: center;
    gap: 30px !important;
  }
  .how img {
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .page_box{
    padding-top: 35px;
  }
  .page_header {
    padding: 150px 0;
    text-align: center;
  }
  .page_header button,
  .page_header img {
    margin: 0 auto;
  }
  .platform .box {
    width: 288px;
  }
  .page_header img {
    width: 130px;
  }
  .page_header h1 {
    flex-wrap: wrap;
    gap: 4px;
  }
  .history_table .flx {
    flex-direction: column;
  }
  .history_table .flx h4 {
    margin-bottom: 10px;
  }
  .footer {
    text-align: center;
  }
  .how .accordion {
    margin-left: 13px;
  }
  .head_box h3 {
    font-size: 30px;
  }
  .staking .box .inbox {
    flex-direction: column;
    align-items: flex-start;
  }
  .page_box .nav {
    flex-direction: column;
    padding: 10px;
    gap: 5px;
  }
  .page_box .nav .nav-item {
    width: 100% !important;
  }
  .page_box .nav .nav-link {
    border-radius: 10px !important;
  }
  .footer .right_box {
    text-align: center;
    width: 100%;
  }
  .footer .right_box ul {
    justify-content: center;
  }
  .footer .blw {
    align-items: center;
  }
  .platform .platform_bg {
    padding: 25px 20px;
  }
}
@media (max-width: 575px) {
  .joinnow input {
    max-width: 100%;
  }
}
.claim_btn{
  margin-top: 10px;
}

/* [alt="roulette-static"]{
  filter: invert(1);
} */


.gTLZXx{
  filter: invert(1);
}

